<template>
<footer class="footer">
      <div class="footer-content">
        <div class="contact-info">
          <h4>Contáctanos</h4>
          <p>Correo electrónico: <a href="mailto:laesenciadetaxco@gmail.com"
              class="email">laesenciadetaxco@gmail.com</a></p>
          <p><a href="/acerca-de" class="about-link">Conoce más sobre nosotros</a></p>
        </div>
        <div class="social-media">
          <h4>Síguenos en redes sociales</h4>
          <div class="social-icons">
            <a href="https://www.instagram.com/laesenciadetaxco/" target="_blank">
              <img src="/src/assets/icons/insta.png" alt="Instagram" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61566874025183" target="_blank">
              <img src="/src/assets/icons/facebook.png" alt="Facebook" />
            </a>
            <a href="https://www.tiktok.com/@laesenciadetaxco" target="_blank">
              <img src="/src/assets/icons/tiktok.png" alt="TikTok" />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-description">
        <p>La Esencia de Taxco - Tu tienda 100% mexicana dedicada a ofrecer los mejores productos de plata artesanal,
          resaltando la elegancia y el lujo que mereces.</p>
      </div>
    </footer>

</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

.footer {
  background-color: #252525;
  color: #D6CDAF;
  padding: 10px;
  text-align: center;

  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.footer-content {
  display: flex;
  justify-content: space-around; /* Distribuye el contenido */
  align-items: center; /* Centra verticalmente */
  margin-bottom: 20px; /* Espaciado inferior */
}

.contact-info h4, .social-media h4 {
  margin-bottom: 10px;
  
}
.contact-info p{
  font-size: 20px;
}
.email {
  color: #D6CDAF;
  text-decoration: none; /* Sin subrayado */
}

.about-link {
  color: #D6CDAF;
  text-decoration: underline; /* Subrayado para resaltar */
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
  
}

.footer-description {
  font-size: 14px; /* Tamaño de fuente más pequeño */
  margin-top: 10px;
  border-top: 1px solid #D6CDAF; /* Línea separadora */
  padding-top: 10px; /* Espaciado superior */
}
.footer-description p{
  font-size: 15px;
}

@media (max-width: 768px) {
  .social-icons img {
  width: 30px;
  height: 30px;
  display: block;
  margin-left: 2.5rem;
}

}


</style>
