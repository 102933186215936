import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue'; // Cambié AcercaDe a About para consistencia
import Store from '@/views/Store.vue'; // Cambié Tienda a Store para consistencia en inglés
import Cart from '@/views/Carrito.vue';
import Carrito from '@/views/Carrito.vue';
import Catalogo from '@/views/Catalogo.vue';
import Promociones from '@/views/Promociones.vue';



const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/acerca-de',
    name: 'about', // Cambié 'acercaDe' a 'about' para consistencia
    component: About,
  },
  {
    path: '/tienda',
    name: 'store', // Cambié 'tienda' a 'store' para consistencia
    component: Store,
  },

  {
    path: '/Catalogo',
    name: 'catalog', // Cambié 'tienda' a 'store' para consistencia
    component: Catalogo,
  },

  {
    path: '/Promociones',
    name: 'promociones', // Cambié 'tienda' a 'store' para consistencia
    component: Promociones,
  },

  {
    path: '/producto/:id',
    name: 'product-detail', // Asegúrate de que sea consistente en toda la aplicación
    component: () => import('@/views/ProductDetails.vue'),
    props: true,
  },
  {
    path: '/carrito',
    name: 'cart',
    component: Cart,
  },
  {
    path: '/tienda/:categoria',
    name: 'tienda',
    component: () => import('@/views/ProductList.vue') // Ajusta el componente según tu estructura
  },
  {
    path: '/success',
    name: 'Pago Exitoso', 
    component: Carrito,
  },
  {
    path: '/failure',
    name: 'Error de Pago', 
    component: Carrito,
  },
  {
    path: '/pending',
    name: 'Pago Pendiente', 
    component: Carrito,
  },
  
  
  // Agrega más rutas si lo necesitas
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
