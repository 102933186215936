<template>
  <div class="cart-page">
    <h1>Carrito de Compras</h1>

    <div v-if="cartItems.length > 0" class="cart-items">
      <div v-for="(item, index) in cartItems" :key="index" class="cart-item" data-aos="fade-up"
        :data-aos-delay="index * 100">
        <img :src="item.Imagen" alt="Imagen del producto" class="cart-item-image" />
        <div class="cart-item-details">
          <h3>{{ item.Nombre }}</h3>
          <p><strong>Precio:</strong> ${{ item.Precio.toLocaleString('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) }}</p>
          <p><strong>Medida:</strong> {{ item.Tamaño }}</p> <!-- Muestra la medida seleccionada -->

          <!-- Input para modificar la cantidad -->
          <label for="cantidad"><strong>Cantidad:</strong></label>
          <input type="number" v-model.number="item.Cantidad" @input="updateCartQuantity(index, item.Cantidad)" min="1"
            class="quantity-input" />
          <p><strong>Descripción:</strong> {{ item.Descripcion }}</p>
        </div>
        <button class="remove-btn" @click="removeFromCart(index)">Eliminar</button>
      </div>

      <div class="cart-total" data-aos="fade-up" data-aos-delay="300">
        <h2>Total: ${{ totalPrice.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
        </h2>
      </div>

      <!-- Contenedor para alinear el botón de pagar a la derecha -->
      <div class="pay-button-container" data-aos="fade-up" data-aos-delay="70">
        <button class="pay-btn" @click="proceedToCheckout">Pagar</button>
      </div>
    </div>

    <div v-else>
      <p>Tu carrito está vacío.</p>
    </div>

    <!-- Botón flotante de WhatsApp -->
    <a 
      href="https://api.whatsapp.com/send?phone=+5646516707&text=Hola,%20quisiera%20hablar%20con%20un%20agente" 
      target="_blank" 
      class="whatsapp-float"
      aria-label="Chat en WhatsApp"
    >
      <img 
        src="https://pluspng.com/img-png/whatsapp-logo-png-whatsapp-logo-png-transparent-amp-svg-vector-pluspng-2400x2400.png" 
        alt="WhatsApp" 
        class="whatsapp-icon"
      />
    </a>

    <!-- Modal de pago -->
    <div v-if="showCheckoutPopup" class="modal-overlay" @click.self="closeCheckoutModal">
      <div class="modal-content">
        <h2>Información de Pago</h2>
        <form @submit.prevent="validateAndSubmitCheckout">
          <label for="nombre">Nombre Completo</label>
          <input type="text" v-model="checkoutData.fullName" required />

          <label for="correo">Correo Electrónico</label>
          <input type="email" v-model="checkoutData.email" required />
          <p v-if="formErrors.email" class="error-message">Por favor ingresa un correo válido.</p>

          <label for="telefono">Teléfono de Contacto</label>
          <input type="tel" v-model="checkoutData.phone" required />

          <p><strong>Total a Pagar:</strong> ${{ totalPrice.toLocaleString('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) }}</p>

          <button type="submit" class="submit-btn">Confirmar Pago</button>
          <button type="button" class="close-btn" @click="closeCheckoutModal">Cerrar</button>
          
        </form>
      </div>
    </div>
  </div>
</template>




<script>
import { eventBus } from '@/eventBus'; // Importa el eventBus
import { db } from "@/firebase"; // Asegúrate de que esto esté bien configurado
import { Timestamp } from 'firebase/firestore';
import mercadopago from 'mercadopago'; // Importa el SDK

// Importa las funciones necesarias de Firestore
import { addDoc, collection } from 'firebase/firestore';
export default {
  data() {
    return {
      cartItems: [], // Almacena los productos del carrito
      showCheckoutPopup: false, // Controla la visibilidad del modal
      checkoutData: {
        fullName: '',
        email: '',
        phone: ''
      },
      formErrors: {
        email: false,
      },
    };
  },
  computed: {
    // Calcula el precio total de los productos en el carrito
    totalPrice() {
      return this.cartItems.reduce((total, item) => total + item.Precio * item.Cantidad, 0);
    },
  },
  mounted() {
    this.loadCartFromLocalStorage(); // Carga el carrito desde localStorage al montar la página
  },
  methods: {
    // Cargar el carrito desde localStorage
    loadCartFromLocalStorage() {
      const storedCart = JSON.parse(localStorage.getItem('cart'));
      if (storedCart) {
        this.cartItems = storedCart; // Actualiza el carrito en la vista
      } else {
        this.cartItems = []; // Si no hay carrito, asegúrate de que esté vacío
      }
    },
    // Actualiza el contador del carrito cuando hay cambios
    updateCartCount() {
      this.loadCartFromLocalStorage(); // Actualizar el carrito desde localStorage
    },
    // Eliminar un producto del carrito
    removeFromCart(index) {
      this.cartItems.splice(index, 1); // Eliminar producto del array
      localStorage.setItem('cart', JSON.stringify(this.cartItems)); // Actualizar localStorage
      eventBus.cartItemsCount = this.cartItems.length; // Actualizar el contador en el eventBus
      this.updateCartCount(); // Actualizar el contador en la navbar
    },
    // Actualizar la cantidad del producto en el carrito
    updateCartQuantity(index, newQuantity) {
      if (newQuantity >= 1) {
        this.cartItems[index].Cantidad = newQuantity;
        localStorage.setItem('cart', JSON.stringify(this.cartItems));
      }
    },
    // Abrir el modal de pago
    proceedToCheckout() {
      this.showCheckoutPopup = true;
    },
    // Cerrar el modal de pago
    closeCheckoutModal() {
      this.showCheckoutPopup = false;
    },
    // Generador de ID alfanumérico aleatorio
    generateRandomId() {
      return Math.random().toString(36).substring(2, 10); // Genera un string alfanumérico
    },
    // Validar y enviar el formulario de pago
    async validateAndSubmitCheckout() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.formErrors.email = !emailPattern.test(this.checkoutData.email);

      if (!this.formErrors.email) {
        try {
          const purchaseId = Math.random().toString(36).substr(2, 9); // Genera un ID aleatorio

          // Guardar la compra en la colección "Compras" con el campo "Estado"
          const purchaseRef = await addDoc(collection(db, 'Compras'), {
            NombreCompleto: this.checkoutData.fullName,
            CorreoElectronico: this.checkoutData.email,
            Telefono: this.checkoutData.phone,
            id: purchaseId,
            Fecha: Timestamp.now(), // Agrega la fecha y hora actual
            Estado: "Activo", // Agrega el estado del pedido como "Activo"
          });

          // Guardar los productos en la subcolección "ProductosComprados"
          const productsCollection = collection(purchaseRef, 'ProductosComprados');
          for (const item of this.cartItems) {
            await addDoc(productsCollection, {
              NombreProd: item.Nombre,
              Cantidad: item.Cantidad,
              Medida: item.Tamaño,
              PrecioTotal: item.Precio * item.Cantidad,
            });
          }

          console.log('Compra registrada correctamente:', purchaseId);

          // Borrar el carrito de localStorage
          localStorage.removeItem('cart');
          this.cartItems = []; // Vacía el carrito en la vista

          // Recargar la página
          window.location.reload();

        } catch (error) {
          console.error('Error al registrar la compra:', error);
        }
      }
    },
  }
}
</script>




<style scoped>
/* Estilos del botón flotante de WhatsApp */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

/* Estilo del ícono dentro del botón */
.whatsapp-icon {
  width: 30px;
  height: 30px;
}


.cart-page {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 20px;
  max-width: 1000px;

  background-color: #1a1a1a;
  /* Fondo oscuro */
  color: #C0C0C0;
  /* Texto metálico */
}

h1 {
  text-align: center;
  color: #D6CDAF;
  /* Oro suave */
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #353535;
  /* Fondo oscuro de las tarjetas */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  /* Sombra más oscura */
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 20px;
}

.cart-item-details h3 {
  margin: 0;
  color: #D6CDAF;
  /* Oro suave */
}

.cart-item-details p {
  margin: 5px 0;
  color: #C0C0C0;
  /* Texto metálico */
}

.remove-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-btn:hover {
  background-color: #c82333;
}

.cart-total {
  text-align: right;
  margin-top: 20px;
  color: #D6CDAF;
  /* Oro suave */
}

.cart-total h2 {
  color: #D6CDAF;
  /* Oro suave */
}

.quantity-input {
  margin-top: 10px;
  width: 60px;
  padding: 5px;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #252525;
  /* Fondo oscuro */
  color: #C0C0C0;
  /* Texto metálico */
}

.quantity-input:focus {
  border-color: #D6CDAF;
  /* Color del borde al hacer foco */
  outline: none;
  /* Sin contorno al hacer foco */
}

.pay-button-container {
  display: flex;
  justify-content: flex-end;
  /* Alinear al final del contenedor */
  margin-top: 20px;
  /* Separación de los elementos anteriores */
}

.pay-btn {
  background-color: #003366;
  /* Verde oscuro */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 20%;
  /* Ancho del botón */
}

.pay-btn:hover {
  background-color: #096d1f;
  /* Color más oscuro al pasar el ratón */
  transform: scale(1.05);
  /* Efecto de escala al pasar el ratón */
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-image {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .cart-item-details {
    margin-left: 0;
  }

  .pay-btn {
    width: 100%;
    /* Botón de pagar ocupa todo el ancho */
  }

  h1 {
    font-size: 1.5rem;
    /* Título más pequeño en pantallas pequeñas */
  }

  .cart-page {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .cart-item-image {
    width: 60px;
    height: 60px;
  }

  .quantity-input {
    width: 50px;
  }
}

.footer {
  background-color: #252525;
  color: #D6CDAF;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

}

.footer-content {
  display: flex;
  justify-content: space-around;
  /* Distribuye el contenido */
  align-items: center;
  /* Centra verticalmente */
  margin-bottom: 20px;
  /* Espaciado inferior */
}

.contact-info h4,
.social-media h4 {
  margin-bottom: 10px;

}

.contact-info p {
  font-size: 20px;
}

.email {
  color: #D6CDAF;
  text-decoration: none;
  /* Sin subrayado */
}

.about-link {
  color: #D6CDAF;
  text-decoration: underline;
  /* Subrayado para resaltar */
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

.footer-description {
  font-size: 14px;
  /* Tamaño de fuente más pequeño */
  margin-top: 10px;
  border-top: 1px solid #D6CDAF;
  /* Línea separadora */
  padding-top: 10px;
  /* Espaciado superior */
}

.footer-description p {
  font-size: 15px;
}

/* Estilos para el modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background: #252525;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content label {
  display: block;
  margin-bottom: 5px;
}

.modal-content input {
  width: 94%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #C0C0C0;
  border-radius: 4px;
  background-color: #252525;
  color: #C0C0C0;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-btn {
  width: 100%;
  padding: 10px;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
