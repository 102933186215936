<template>
  <div class="products">
    <div v-for="(product, index) in products" :key="index" class="product-card" data-aos="fade-up" data-aos-delay="100">
      <router-link :to="{ name: 'product-detail', params: { id: product.id } }" class="product-link">
        <img :src="product.imagen[0]" :alt="product.Nombre" class="product-image" data-aos="zoom-in"
          data-aos-delay="200" loading="lazy" />
        <h2 class="product-name" data-aos="fade-up" data-aos-delay="300">{{ product.Nombre }}</h2>
        <p data-aos="fade-up" data-aos-delay="400"><strong>Precio:</strong> {{ product.Precio.toLocaleString('es-MX', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) }}</p>
      </router-link>
    </div>
    <a 
      href="https://api.whatsapp.com/send?phone=+5646516707&text=Hola,%20quisiera%20hablar%20con%20un%20agente" 
      target="_blank" 
      class="whatsapp-float"
      aria-label="Chat en WhatsApp"
    >
      <img 
        src="https://pluspng.com/img-png/whatsapp-logo-png-whatsapp-logo-png-transparent-amp-svg-vector-pluspng-2400x2400.png" 
        alt="WhatsApp" 
        class="whatsapp-icon"
      />
    </a>


  </div>
</template>

<script>
import { db } from "@/firebase"; // Asegúrate de que esto esté bien configurado
import { collection, getDocs } from "firebase/firestore"; // Importa las funciones necesarias de Firestore

export default {

  metaInfo() {
    return {
      title: "Tienda de Joyería Artesanal De Plata - La Esencia de Taxco",
      meta: [
        {
          name: "description",
          content: "Explora nuestra tienda online de joyería artesanal en plata. Encuentra cadenas, pulseras, y más piezas únicas de Taxco, México.",
        },
        {
          name: "keywords",
          content: "tienda de joyería, plata Taxco, cadenas de plata, pulseras de plata, joyería artesanal, plata .925, plata .950",
        },
        {
          property: "og:title",
          content: "Tienda de Joyería Artesanal De Plata - La Esencia de Taxco",
        },
        {
          property: "og:description",
          content: "Descubre la belleza de nuestra joyería de plata artesanal en Taxco. Envíos a todo el país y productos de alta calidad.",
        },
        {
          property: "og:image",
          content: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/logoCuadrado.jpeg?alt=media&token=ade49493-e6d0-4665-9e0c-583e631c7b49", // Cambia esto a la URL de la imagen que deseas mostrar
        },
        {
          property: "og:type",
          content: "website",
        }
      ],
    };
  },

  data() {
    return {
      products: [], // Array para almacenar los productos
    };
  },
  async created() {
    await this.fetchProducts(); // Llamar a la función para obtener productos cuando se crea el componente
  },
  methods: {
    async fetchProducts() {
      try {
        const productsCollection = collection(db, "Productos"); // Reemplaza 'Productos' por el nombre de tu colección
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map((doc) => ({
          id: doc.id, // Asegúrate de obtener el ID del documento
          ...doc.data(), // Obtiene los datos de cada producto
        }));
        this.products = productList; // Asigna la lista de productos al array products
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    },
  },
};
</script>


<style scoped>
.products {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Centra las tarjetas */
}

.product-card {
  background-color: #252525;
  /* Fondo oscuro */
  border-radius: 10px;
  /* Esquinas redondeadas */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  /* Sombra de elevación */
  margin: 20px;
  padding: 20px;
  text-align: center;
  /* Centra el contenido */
  transition: transform 0.3s, box-shadow 0.3s;
  /* Transiciones suaves */
  width: 300px;
  height: 350px;
  /* Ancho y alto fijos */
  overflow: hidden;
  /* Evita que el contenido desborde */
  color: #C0C0C0;
  /* Color del texto metálico */
}

.product-card:hover {
  transform: translateY(-5px) scale(1.05);
  /* Efecto de elevación y zoom al pasar el mouse */
  box-shadow: 0 5px 20px #C0C0C0;
  /* Sombra más pronunciada al pasar el mouse */
}

.product-image {
  width: 100%;
  /* Ajusta la imagen al 100% del contenedor */
  height: 250px;
  /* Mantiene la proporción de la imagen */
  object-fit: cover;
  /* Asegura que las imágenes se mantengan uniformes */
  border-radius: 10px;
  /* Esquinas redondeadas en la imagen */
  aspect-ratio: 500/250;
}

.product-name {
  font-size: 1.2em;
  /* Tamaño de fuente para el nombre del producto */
  margin: 10px 0 5px;
  /* Margen para el nombre */
  color: #D6CDAF;
  /* Oro suave */
}

.product-price {
  font-size: 1.2em;
  /* Tamaño de fuente para el precio */
  color: #D6CDAF;
  /* Oro suave */
}

.product-link {
  text-decoration: none;
  /* Quitar subrayado del enlace */
  color: inherit;
  /* Hereda el color del texto */
  display: block;
  /* Hace que el enlace cubra todo el contenedor */
  height: 100%;
  /* Hace que el enlace cubra toda la altura */
}

/* Estilos del botón flotante de WhatsApp */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

/* Estilo del ícono dentro del botón */
.whatsapp-icon {
  width: 30px;
  height: 30px;
}
</style>
