// store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    cartItems: [],
  },
  mutations: {
    ADD_TO_CART(state, product) {
      state.cartItems.push(product);
    },
  },
  actions: {
    addToCart({ commit }, product) {
      commit('ADD_TO_CART', product);
    },
  },
  getters: {
    cartItems: (state) => state.cartItems,
  },
});

export default store;
