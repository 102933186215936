<template>
  <div id="app">
    <Navbar />
    <div class="content">
      <router-view />
    </div>
    <Footer /> <!-- Asegúrate de incluir el componente Footer aquí -->
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue'; // Asegúrate de importar el Footer
import 'vue-slick-carousel/dist/vue-slick-carousel.css';


export default {
  components: {
    Navbar,
    Footer, // Añadir el Footer aquí
  },
  mounted() {
    AOS.init(); // Inicializamos AOS para que las animaciones funcionen al hacer scroll
  }
}
</script>

<style>
#app {
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column; /* Permite que los elementos se coloquen uno debajo del otro */
  min-height: 100vh; /* Asegúrate de que ocupe al menos la altura de la ventana */
}

.content {
  flex: 1; /* Permite que esta sección ocupe el espacio restante */
  padding-top: 80px; /* Ajusta este valor a la altura de tu navbar de escritorio */
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .content {
    padding-top: 130px; /* Ajusta este valor según la altura de la navbar móvil */
  }
}
</style>
