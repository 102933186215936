<template>
  <div class="titulo">
    ¡¡Promociones Por tiempo limitado!!
  </div>

  <div class="products">
    <div
      v-for="(product, index) in filteredProducts"
      :key="index"
      class="product-card"
      data-aos="fade-up"
      :data-aos-delay="index * 100"
    >
      <router-link :to="{ name: 'product-detail', params: { id: product.id } }" class="product-link">
        <img :src="product.imagen[0]" :alt="product.Nombre" class="product-image" />
        <h2 class="product-name">{{ product.Nombre }}</h2>
        <!-- Mostrar el precio anterior tachado y el precio actual -->
        <p class="product-price">
          <span class="old-price">Antes: ${{product.PrecioAnterior }}</span>
          <span class="current-price">Ahora: ${{+product.Precio }}</span>
        </p>
      </router-link>
    </div>
    <a 
      href="https://api.whatsapp.com/send?phone=+5646516707&text=Hola,%20quisiera%20hablar%20con%20un%20agente" 
      target="_blank" 
      class="whatsapp-float"
      aria-label="Chat en WhatsApp"
    >
      <img 
        src="https://pluspng.com/img-png/whatsapp-logo-png-whatsapp-logo-png-transparent-amp-svg-vector-pluspng-2400x2400.png" 
        alt="WhatsApp" 
        class="whatsapp-icon"
      />
    </a>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      products: [],
      filteredProducts: [], // Productos filtrados por promoción
    };
  },
  async created() {
    await this.fetchProducts();
    this.filterPromotions(); // Filtrar productos en promoción al crear el componente
  },
  methods: {
    async fetchProducts() {
      try {
        const productsCollection = collection(db, "Productos");
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.products = productList;
        this.filterPromotions(); // Filtrar después de cargar los productos
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    },
    filterPromotions() {
      this.filteredProducts = this.products.filter(product => product.Promocion === true);
    },
  },
});
</script>


<style scoped>

.old-price {
  text-decoration: line-through;
  color: gray;
  margin-right: 10px;
}

/* Estilo para el precio actual */
.current-price {
  color: #ff5722; /* Cambia este color según tu diseño */
  font-weight: bold;
}

.product-priceanterior{
  font-size: 1.2rem;
  color: #D6CDAF; /* Oro suave */
  font-weight: 600;
  margin-top: 5px;
}

.titulo {
  font-size: 50px;
  font-weight: bold;
  color: #ff0040;
  margin-top: 1.5rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* Animación */
  animation: bounceScale 2s infinite ease-in-out;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

/* Definir la animación */
@keyframes bounceScale {
  0% {
    transform: scale(1) translateY(0); /* Estado inicial */
  }
  25% {
    transform: scale(1.1) translateY(-10px); /* Se agranda y sube */
  }
  50% {
    transform: scale(0.9) translateY(0); /* Se encoge y vuelve a la posición */
  }
  75% {
    transform: scale(1.1) translateY(-5px); /* Se agranda ligeramente y sube menos */
  }
  100% {
    transform: scale(1) translateY(0); /* Regresa al estado inicial */
  }
}


/* Estilos del botón flotante de WhatsApp */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

/* Estilo del ícono dentro del botón */
.whatsapp-icon {
  width: 30px;
  height: 30px;
}

/* Contenedor de productos */
.products {
  margin-top:1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px; /* Espacio uniforme entre tarjetas */
  padding: 20px; /* Espaciado alrededor */
  background-color: #1a1a1a; /* Fondo oscuro */
}

/* Estilo para cada tarjeta de producto */
.product-card {
  background-color: #252525; /* Fondo oscuro */
  border-radius: 12px; /* Bordes redondeados */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Sombra suave para darle profundidad */
  overflow: hidden; /* Evita que el contenido se desborde */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 300px; /* Ancho fijo */
  text-align: center;
  padding: 20px;
  height: 350px;
  color: #C0C0C0; /* Texto metálico */
}

/* Animación al hacer hover sobre la tarjeta */
.product-card:hover {
  transform: translateY(-10px); /* Eleva la tarjeta ligeramente */
  box-shadow: 0 5px 15px #C0C0C0; /* Sombra más pronunciada */
}

/* Imagen del producto */
.product-image {
  width: 100%; /* Imagen ocupa todo el ancho */
  height: 250px; /* Altura fija */
  object-fit: cover; /* Mantiene la proporción de la imagen */
  border-radius: 8px; /* Bordes redondeados */
  margin-bottom: 15px; /* Espacio debajo de la imagen */
}

/* Nombre del producto */
.product-name {
  font-size: 1.4rem;
  color: #D6CDAF; /* Oro suave */
  margin-bottom: 10px;
  font-weight: 500;
}

/* Precio del producto */
.product-price {
  font-size: 1.2rem;
  color: #D6CDAF; /* Oro suave */
  font-weight: 600;
  margin-top: 5px;
}

/* Estilo para el enlace que cubre toda la tarjeta */
.product-link {
  text-decoration: none; /* Sin subrayado */
  color: inherit; /* Hereda el color del texto */
  display: block;
}

.footer {
  background-color: #252525;
  color: #D6CDAF;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.footer-content {
  display: flex;
  justify-content: space-around; /* Distribuye el contenido */
  align-items: center; /* Centra verticalmente */
  margin-bottom: 20px; /* Espaciado inferior */
}

.contact-info h4, .social-media h4 {
  margin-bottom: 10px;
  
}
.contact-info p{
  font-size: 20px;
}
.email {
  color: #D6CDAF;
  text-decoration: none; /* Sin subrayado */
}

.about-link {
  color: #D6CDAF;
  text-decoration: underline; /* Subrayado para resaltar */
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

.footer-description {
  font-size: 14px; /* Tamaño de fuente más pequeño */
  margin-top: 10px;
  border-top: 1px solid #D6CDAF; /* Línea separadora */
  padding-top: 10px; /* Espaciado superior */
}
.footer-description p{
  font-size: 15px;
}
@media (max-width: 913px) {
  .titulo {
  width: 90%;
  font-size: 35px;
  font-weight: bold;
  color: #ff0040;
  margin-top: 1.5rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* Animación */
  animation: bounceScale 2s infinite ease-in-out;
  margin-left: auto;
  margin-right: auto;
}

/* Definir la animación */
@keyframes bounceScale {
  0% {
    transform: scale(1) translateY(0); /* Estado inicial */
  }
  25% {
    transform: scale(1.1) translateY(-10px); /* Se agranda y sube */
  }
  50% {
    transform: scale(0.9) translateY(0); /* Se encoge y vuelve a la posición */
  }
  75% {
    transform: scale(1.1) translateY(-5px); /* Se agranda ligeramente y sube menos */
  }
  100% {
    transform: scale(1) translateY(0); /* Regresa al estado inicial */
  }
}
}
</style>
