<template>
  <div class="home-container">
    <!-- Sección Hero con slider automático -->
    <section class="hero-section" data-aos="fade-up" data-aos-duration="1500">
      <Splide 
        :options="splideOptions" 
        class="splide-slider" 
        ref="splide"
        @moved="onSlideMoved"
      >
        <SplideSlide v-for="(slide, index) in slides" :key="index">
          <div class="slide-content">
            <div class="slide-text" data-aos="fade-right" data-aos-duration="1500">
              <h1>{{ slide.title }}</h1>
              <p>{{ slide.description }}</p>
            </div>
            <div class="image-container" data-aos="fade-left" data-aos-duration="1500">
              <!-- Mostrar el video solo si la URL es un archivo .mp4 -->
              <template v-if="slide.image.endsWith('.mp4')">

              </template>
              <!-- Si no es un video, mostrar la imagen -->
              <template v-else>
                <img 
                  :src="slide.image" 
                  alt="Slide Image" 
                  class="slide-image" 
                  width="100%" 
                  height="100%" loading="lazy"
                />
              </template>
            </div>
            <button 
              @click="exploreStore" 
              data-aos="zoom-in" 
              data-aos-duration="1500"
            >
              Explorar la tienda
            </button>
          </div>
        </SplideSlide>
      </Splide>
    </section>

    <!-- Sección destacada para categorías o productos principales -->
    <section class="featured-section" data-aos="fade-up" data-aos-duration="1500">
      <h2 data-aos="fade-down" data-aos-duration="1500">Categorías Destacadas</h2>
      <div class="categories-grid">
        <div class="category-card" 
             v-for="(category, index) in categories" 
             :key="index" 
             @click="goToCategory(category)" 
             data-aos="flip-left" 
             data-aos-duration="1500">

          <img :src="category.image" :alt="category.name" class="category-image" loading="lazy" />

          <h3>{{ category.name }}</h3>
        </div>
      </div>
    </section>

    <!-- Sección con promociones o mensajes inspiradores -->
    <section class="promo-section" data-aos="fade-up" data-aos-duration="1500">
      <div class="promo-content">
        <h2 data-aos="zoom-in" data-aos-duration="1500">Descubre nuestras promociones exclusivas</h2>
        <p data-aos="fade-up" data-aos-duration="1500">Ofertas especiales disponibles por tiempo limitado.</p>
        <button 
          @click="explorePromotions" 
          data-aos="zoom-in" 
          data-aos-duration="1500"
        >
          Ver promociones
        </button>
      </div>
    </section>

    <!-- Testimonios o sección de confianza -->
    <section class="testimonial-section" data-aos="fade-up" data-aos-duration="1500">
      <h2 data-aos="fade-down" data-aos-duration="1500">Lo que nuestros clientes dicen</h2>
      <div class="testimonials">
        <div class="testimonial-card" 
             v-for="(testimonial, index) in testimonials" 
             :key="index" 
             data-aos="flip-up" 
             data-aos-duration="1500">
          <p>{{ testimonial.quote }}</p>
          <h4>{{ testimonial.author }}</h4>
        </div>
      </div>
    </section>

    <a 
      href="https://api.whatsapp.com/send?phone=+5646516707&text=Hola,%20quisiera%20hablar%20con%20un%20agente" 
      target="_blank" 
      class="whatsapp-float"
      aria-label="Chat en WhatsApp"
    >
      <img 
        src="https://pluspng.com/img-png/whatsapp-logo-png-whatsapp-logo-png-transparent-amp-svg-vector-pluspng-2400x2400.png" 
        alt="WhatsApp" 
        class="whatsapp-icon"
      />
    </a>

  </div>
</template>





<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  components: {
    Splide,
    SplideSlide
  },
  metaInfo() {
    return {
      title: "Inicio - La Esencia de Taxco | Joyería Artesanal en Plata",
      meta: [
        {
          name: "description",
          content:
            "Bienvenidos a La Esencia de Taxco, donde ofrecemos joyería de plata artesanal hecha a mano en México. Descubre elegancia y calidad en cada pieza única.",
        },
        {
          name: "keywords",
          content: "joyería de plata, artesanía mexicana, La Esencia de Taxco, calidad, lujo, elegancia",
        },
        {
          property: "og:title",
          content: "La Esencia de Taxco - Joyería Artesanal en Plata de Calidad",
        },
        {
          property: "og:description",
          content:
            "Explora la autenticidad y elegancia de la joyería de plata artesanal en La Esencia de Taxco. Cada pieza está hecha a mano en México con los más altos estándares de calidad.",
        },
        {
          property: "og:image",
          content: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Logo.png?alt=media&token=5cd4d6d5-f7a9-419d-9500-f42fcbc77942", // Imagen representativa
        },
        {
          property: "og:type",
          content: "website",
        },
      ],
    };
  },
  data() {
    return {
      slides: [
        { title: "Bienvenidos a Nuestra Tienda", description: "Donde la Elegancia se Viste de Plata.", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Logo.png?alt=media&token=5cd4d6d5-f7a9-419d-9500-f42fcbc77942" },
        { title: "Nuevas colecciones", description: "Descubre las tendencias de la temporada", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/La%20esencia%20de%20taxco.png?alt=media&token=6f886098-4085-4813-8642-071c1eff2ce7" },
        { title: "Ofertas Exclusivas", description: "Aprovecha nuestras promociones por tiempo limitado", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Dise%C3%B1o%20sin%20t%C3%ADtulo.png?alt=media&token=0388c489-b5e9-4cfd-88bd-a3949cbc691d" },
        { title: "El Buen fin se acerca", description: "Aprovecha nuestros precios mas que bajos", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Banner%20Cl%C3%A1sico%20Env%C3%ADos%20Gratis%20Moderno%20Gris%20y%20Blanco(1).png?alt=media&token=5d035746-cd25-458a-828c-1a7cd37810d8" },
      ],
      categories: [
        { name: "Collares", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Cadenas%2FcollarBatallosa.png?alt=media&token=6149001a-b9c3-4d03-b27b-cb5c9e7a944f" },
        { name: "Pulseras", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Pulseras%2Fpulseras%20escama.png?alt=media&token=25b3e101-3d25-41fa-8009-4ab63946adf7" },
        //{ name: "Anillos", image: "https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Anillos%2Fanillo.png?alt=media&token=95625e4f-3f5c-414d-8760-8525355279a4" },
      ],
      testimonials: [
        { quote: "Productos de excelente calidad y servicio al cliente excepcional.", author: "Ana García" },
        { quote: "Rápido envío y una selección increíble de productos.", author: "Luis Martínez" },
      ],
      splideOptions: {
        type: 'loop',
        autoplay: true,
        interval: 7000,
        pauseOnHover: true,
        pauseOnFocus: false,
      },
    };
  },
  methods: {
    exploreStore() {
      this.$router.push({ name: 'store' });
    },
    goToCategory(category) {
      this.$router.push({ name: 'tienda', params: { categoria: category.name } });
    },
    explorePromotions() {
      this.$router.push({ name: 'promociones' });
    },
    onSlideMoved() {
      const currentSlide = this.$refs.splide.splide.index;
      const videos = this.$refs.splide.$el.querySelectorAll('video');
      
      // Detener videos en otras diapositivas
      videos.forEach((video, index) => {
        if (index !== currentSlide) {
          video.pause();
        }
      });

      // Reproducir el video en la diapositiva actual si existe
      const currentVideo = videos[currentSlide];
      if (currentVideo) {
        currentVideo.play();
      }
    }
  }
};
</script>

<style scoped>



.home-container {
  font-family: 'Helvetica Neue', sans-serif;
  color: #333;
  background-color: #1A1A1A;
  /* Fondo negro profundo */
}

/* Sección Hero */
.hero-section {
  position: relative;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splide-slider {
  width: 100%;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000000;
  /* Texto blanco */
  width: 100%;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

/* Ajuste del tamaño de la imagen del slider */
.image-container {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff00;
  /* Fondo suave */
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slide-text h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #D6CDAF;
  /* Oro suave */
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.slide-text p {
  font-size: 1.35rem;
  color: #C0C0C0;
  /* Plata metálico */
}

button {
  background-color: #003366;
  /* Azul marino */
  color: #FFFFFF;
  /* Texto blanco */
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.slide-text button:hover {
  background-color: #218838;
  /* Verde suave */
}

/* Sección de categorías destacadas */
.featured-section {
  padding: 60px 20px;
  text-align: center;
  color: #D6CDAF;
  /* Oro suave */
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: large;
}

.categories-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  font-size: 25px;
}

.category-card {
  background-color: #252525;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 350px;
}

.category-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(185, 185, 185, 0.2);
}

.category-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.category-card h3 {
  padding: 15px;
  font-size: 1.5rem;
  color: #C0C0C0;
  /* Plata metálico */
  font-family: Georgia, 'Times New Roman', Times, serif;
}

/* Sección promocional */
.promo-section {
  background-color: #252525;
  padding: 60px 20px;
  text-align: center;
  color: #C0C0C0;
}

.promo-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #D6CDAF;
  /* Oro suave */
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.promo-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.promo-content button {
  background-color: #003366;
  /* Azul marino */
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.promo-content button:hover {
  background-color: #218838;
  /* Verde suave */
}

/* Sección de testimonios */
.testimonial-section {
  padding: 60px 20px;
  text-align: center;
  color: #D6CDAF;
}

.testimonials {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: #252525;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.testimonial-card p {
  font-style: italic;
}

.testimonial-card h4 {
  margin-top: 15px;
  font-weight: bold;
  color: #D6CDAF;
  /* Oro suave */
}

.footer {
  background-color: #252525;
  color: #D6CDAF;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.footer-content {
  display: flex;
  justify-content: space-around; /* Distribuye el contenido */
  align-items: center; /* Centra verticalmente */
  margin-bottom: 20px; /* Espaciado inferior */
}

.contact-info h4, .social-media h4 {
  margin-bottom: 10px;
  
}
.contact-info p{
  font-size: 20px;
}
.email {
  color: #D6CDAF;
  text-decoration: none; /* Sin subrayado */
}

.about-link {
  color: #D6CDAF;
  text-decoration: underline; /* Subrayado para resaltar */
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

.footer-description {
  font-size: 14px; /* Tamaño de fuente más pequeño */
  margin-top: 10px;
  border-top: 1px solid #D6CDAF; /* Línea separadora */
  padding-top: 10px; /* Espaciado superior */
}
.footer-description p{
  font-size: 15px;
}

@media (max-width: 913px) {
/* Ajuste del tamaño de la imagen del slider */
.image-container {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff00;
  /* Fondo suave */
}
.slide-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.slide-text h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #D6CDAF;
  /* Oro suave */
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.slide-text p {
  font-size: 1.5rem;
  color: #C0C0C0;
  /* Plata metálico */
}
}

/* Estilos del botón flotante de WhatsApp */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

/* Estilo del ícono dentro del botón */
.whatsapp-icon {
  width: 30px;
  height: 30px;
}



</style>
