<template>
  <div class="about-container">
    <section class="hero" data-aos="fade-up" data-aos-duration="1500">
      <div class="hero-content" data-aos="zoom-in" data-aos-duration="1500">
        <h1>Elegancia y Artesanía Mexicana</h1>
        <p data-aos="fade-up" data-aos-duration="1500">
          Somos una empresa 100% mexicana, dedicada a ofrecer productos de plata de la más alta calidad. Nuestros diseños destacan por la elegancia y el lujo que representan, y cada pieza está elaborada artesanalmente en el histórico pueblo de Taxco.
        </p>
        <router-link to="/tienda">
          <button class="cta-button" data-aos="fade-up" data-aos-duration="1500">Descubre Nuestros Productos</button>
        </router-link>
      </div>
    </section>

    <section class="about-details" data-aos="fade-up" data-aos-duration="1500">
      <div class="about-text" data-aos="fade-right" data-aos-duration="1500">
        <h2>Nuestra Historia</h2>
        <p data-aos="fade-up" data-aos-duration="1500">
          Con una profunda pasión por el arte de la platería, hemos recorrido un camino que fusiona tradición y modernidad. Cada una de nuestras piezas es creada por artesanos mexicanos expertos, quienes han perfeccionado sus técnicas a lo largo de generaciones.
        </p>
        <p data-aos="fade-up" data-aos-duration="1500">
          Nos enorgullece ofrecer productos que no solo destacan por su diseño elegante, sino también por la historia que llevan consigo. Cada joya es única, reflejando la dedicación y maestría de las manos que las crearon.
        </p>
      </div>

      <div class="about-values" data-aos="fade-left" data-aos-duration="1500">
        <h2>¿Por qué elegirnos?</h2>
        <ul>
          <li data-aos="zoom-in" data-aos-duration="1500"><strong>100% Mexicano:</strong> Todos nuestros productos son fabricados en México, apoyando la economía local.</li>
          <li data-aos="zoom-in" data-aos-duration="1500"><strong>Artesanía de Taxco:</strong> Nuestras piezas son hechas a mano en Taxco, un pueblo conocido por su rica tradición en platería.</li>
          <li data-aos="zoom-in" data-aos-duration="1500"><strong>Alta calidad:</strong> Solo utilizamos la mejor plata para garantizar que nuestros productos sean duraderos y mantengan su brillo.</li>
          <li data-aos="zoom-in" data-aos-duration="1500"><strong>Diseño Elegante:</strong> Cada pieza está diseñada para resaltar la sofisticación y el lujo.</li>
        </ul>
      </div>
    </section>

    <a 
      href="https://api.whatsapp.com/send?phone=+5646516707&text=Hola,%20quisiera%20hablar%20con%20un%20agente" 
      target="_blank" 
      class="whatsapp-float"
      aria-label="Chat en WhatsApp"
    >
      <img 
        src="https://pluspng.com/img-png/whatsapp-logo-png-whatsapp-logo-png-transparent-amp-svg-vector-pluspng-2400x2400.png" 
        alt="WhatsApp" 
        class="whatsapp-icon"
      />
    </a>
  </div>
</template>
<script>

import { defineComponent } from "vue";

export default defineComponent({
  name: 'AboutPage', // Cambia el nombre del componente principal
  metaInfo() {
    return {
      title: "Acerca de Nosotros - La Esencia de Taxco",
      meta: [
        {
          name: "description",
          content:
            "La Esencia de Taxco es una empresa mexicana dedicada a ofrecer productos de plata artesanal de la mejor calidad, inspirados en la elegancia y el lujo de Taxco. Descubre nuestra colección hecha a mano.",
        },
        {
          name: "keywords",
          content: "plata, joyería artesanal, Taxco, productos mexicanos, elegancia, lujo, venta, .925, .950",
        },
        {
          property: "og:title",
          content: "Acerca de La Esencia de Taxco - Productos de Plata Artesanales",
        },
        {
          property: "og:description",
          content:
            "Conoce a La Esencia de Taxco, una empresa 100% mexicana especializada en joyería de plata artesanal, resaltando la elegancia y lujo de los productos hechos en Taxco.",
        },
        {
          property: "og:image",
          content: "URL-de-la-imagen-de-la-empresa-o-producto",
        },
        {
          property: "og:type",
          content: "website",
        },
      ],
    };
  },
});
</script>
<style scoped>

/* Estilos del botón flotante de WhatsApp */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

/* Estilo del ícono dentro del botón */
.whatsapp-icon {
  width: 30px;
  height: 30px;
}


.about-container {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  max-width: 100%;
}

.hero {
  background-image: url('/path/to/your-background-image.jpg');
  background-size: cover;
  background-position: center;
  
  text-align: center;
  color: #fff;
}

.hero-content h1 {
  font-size: 3em;
  color: #D6CDAF;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.hero-content p {
  font-size: 1.3em;
  color: #c0c0c0;
  max-width: 800px;
  margin: 0 auto 30px auto;
  line-height: 1.8;
 
}

.cta-button {
  padding: 15px 30px;
  background-color: #D6CDAF;
  color: #333;
  border: none;
  border-radius: 25px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 30px;
}

.cta-button:hover {
  background-color: #c0b693;
}

.about-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
 
}

.about-text, .about-values {
  background-color: #252525;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.about-text h2, .about-values h2 {
  font-size: 2.5em;
  color: #D6CDAF;
  margin-bottom: 25px;
}

.about-text p, .about-values ul {
  font-size: 1.2em;
  color: #c0c0c0;
  line-height: 1.8;
  text-align: justify;
}

.about-values ul {
  list-style-type: none;
  padding: 0;
}

.about-values li {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.about-values li strong {
  color: #D6CDAF;
  font-weight: bold;
  text-align: justify;
}

@media (min-width: 768px) {
  .about-details {
    flex-direction: row;
    width: 100%;
    
  }
  .about-container {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  width: 100%;
}

  .about-text, .about-values {
    flex: 1;
    margin: 0 20px;
    
  }
  .about-values {
    font-size: medium;
    width: 100%;
  }
}
.footer {
  background-color: #252525;
  color: #D6CDAF;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.footer-content {
  display: flex;
  justify-content: space-around; /* Distribuye el contenido */
  align-items: center; /* Centra verticalmente */
  margin-bottom: 20px; /* Espaciado inferior */
}

.contact-info h4, .social-media h4 {
  margin-bottom: 10px;
  
}
.contact-info p{
  font-size: 20px;
}
.email {
  color: #D6CDAF;
  text-decoration: none; /* Sin subrayado */
}

.about-link {
  color: #D6CDAF;
  text-decoration: underline; /* Subrayado para resaltar */
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

.footer-description {
  font-size: 14px; /* Tamaño de fuente más pequeño */
  margin-top: 10px;
  border-top: 1px solid #D6CDAF; /* Línea separadora */
  padding-top: 10px; /* Espaciado superior */
}
.footer-description p{
  font-size: 15px;
}

</style>
