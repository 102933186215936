import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Importa las rutas
import {StripePlugin} from 'vue-stripe';
import '@splidejs/splide/dist/css/splide.min.css';
import store from './store';
import VuePdfEmbed from 'vue-pdf-embed';



// Si tienes algún plugin o configuración adicional, puedes incluirla aquí

const app = createApp(App);
app.use(VuePdfEmbed);

app.use(store)

app.use(router); // Usa Vue Router

app.mount('#app'); // Monta la aplicación en el elemento con id="app"
