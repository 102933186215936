// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDNUAjHwddIbY0H5DOls6tUO4LQXjEQ11o",
    authDomain: "venta-plata-8deb5.firebaseapp.com",
    projectId: "venta-plata-8deb5",
    storageBucket: "venta-plata-8deb5.appspot.com",
    messagingSenderId: "418832111481",
    appId: "1:418832111481:web:275fe780b98338abd801a8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
