<template>
  <div ref="searchContainer" class="search-container">
    <input 
      type="text" 
      v-model="searchTerm" 
      placeholder="Buscar productos..." 
      @focus="fetchProducts"
      @input="filterProducts" 
      class="search-input"
      ref="searchInput" 
    />
    <div class="ContenPhone">
    <div class="search-results" v-if="filteredProducts.length">
      <ul>
        <li v-for="product in filteredProducts" :key="product.id" class="search-item"
          @click="goToProductDetails(product)">
          <!-- Mostrar la primera imagen del array de imágenes -->
          <img :src="product.imagen[0]" alt="Imagen del producto" class="product-image" />
          <span>{{ product.Nombre }}</span>
          
        </li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>
import { getFirestore, collection, getDocs } from "firebase/firestore";

export default {
  data() {
    return {
      searchTerm: '',  // Almacena el término de búsqueda
      allProducts: [], // Aquí se almacenan todos los productos obtenidos de Firestore
      filteredProducts: [] // Aquí se almacenan los productos filtrados
    };
  },
  mounted() {
    // Escucha los clics globales para cerrar el buscador al hacer clic fuera
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // Elimina el evento cuando el componente se destruye
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    async fetchProducts() {
      try {
        const db = getFirestore();
        const productosCollection = collection(db, 'Productos');
        const querySnapshot = await getDocs(productosCollection);
        const products = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        localStorage.setItem('allProducts', JSON.stringify(products));
        this.allProducts = products;
        this.filteredProducts = products;
      } catch (error) {
        console.error('Error al obtener productos: ', error);
      }
    },
    filterProducts() {
      const term = this.searchTerm.toLowerCase();
      this.filteredProducts = this.allProducts.filter(product =>
        product.Nombre.toLowerCase().includes(term)
      );
    },
    addToCart(product) {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      const existingProduct = storedCart.find(item => item.id === product.id);

      if (existingProduct) {
        storedCart.push({
          id: product.id,
          Nombre: product.Nombre,
          Precio: product.Precio,
          Descripcion: product.Descripcion,
          Imagen: product.imagen[0], // Almacena la primera imagen
          Cantidad: 1
        });
        localStorage.setItem('cart', JSON.stringify(storedCart));
        this.$emit('cartUpdated');
      } else {
        storedCart.push({
          id: product.id,
          Nombre: product.Nombre,
          Precio: product.Precio,
          Descripcion: product.Descripcion,
          Imagen: product.imagen[0], // Almacena la primera imagen
          Cantidad: 1
        });
        localStorage.setItem('cart', JSON.stringify(storedCart));
        this.$emit('cartUpdated');
      }
    },
    async goToProductDetails(product) {
      // Verificar si ya estás en la página de detalles del producto
      if (this.$route.name === 'product-detail' && this.$route.params.id === product.id) {
        // Si estás en la página de detalles del producto y buscas el mismo producto, simplemente actualiza el contenido
        this.searchTerm = ''; // Limpiar el campo de búsqueda
        this.filteredProducts = []; // Limpiar los resultados filtrados
        return; // Salir del método
      }

      // Redirigir a la página de detalles del producto seleccionado
      await this.$router.push({ name: 'product-detail', params: { id: product.id } });

      // Limpiar el campo de búsqueda y los resultados filtrados
      this.searchTerm = '';
      this.filteredProducts = [];
    },
    handleClickOutside(event) {
      const searchContainer = this.$refs.searchContainer;
      if (searchContainer && !searchContainer.contains(event.target)) {
        this.searchTerm = '';
        this.filteredProducts = [];
      }
    }
  },
  beforeDestroy() {
    this.searchTerm = '';
    this.filteredProducts = [];
  }
};
</script>



<style scoped>
.search-container {
  position: relative;
}

.search-input {
  width: 250px;
  padding: 10px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  background-color: #1a1a1a;
  color: #c0c0c0;
  justify-content: center;
}

.search-results {
  max-height: 150px; /* Ajusta esta altura según tu preferencia */
  overflow-y: auto; /* Permite el desplazamiento vertical */
  position: absolute;
  left: 0rem; 
  background-color: #1a1a1a;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  z-index: 10;
  width: 100%;
  color: #c0c0c0;
  justify-content: center;
}

.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.search-item:hover {
  background-color: #353434;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 10px;
}

.search-results button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #085f3e;
  color: white;
  cursor: pointer;
}

.search-results button:hover {
  background-color: #074f30;
}

/* Versión responsiva */
@media (max-width: 768px) {
  .search-input {
    width: 70%; /* Ajusta el ancho para pantallas más pequeñas */
    padding: 8px;
    font-size: 1rem;
  }

  .search-results {
    position: absolute;
    left: 15%;
    
    width: 70%; /* Asegura que el dropdown ocupe el 100% del ancho disponible */
    /*left: 4rem;*/
   
    font-size: small;
    
  }
  .ContenPhone{
    position: relative;
    width: 100%;
  }
  .search-item {
    flex-direction: row; /* Organiza verticalmente los resultados */
    align-items: flex-start;
    padding: 10px;
  }

  .product-image {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .search-results ul {
    padding: 5px;
  }

  .search-results button {
    padding: 8px;
    font-size: 0.9rem;
  }
  .search-container{
    left: -1rem;
  }
}
</style>
