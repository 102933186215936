<template>
  <div class="catalogo-container">
    <h1>Bienvenido a Nuestro Catálogo</h1>
    
    <!-- Incrustar el catálogo usando iframe -->
    <iframe
      src="https://online.pubhtml5.com/pslqh/plui/"
      seamless="seamless"
      scrolling="no"
      frameborder="0"
      allowtransparency="true"
      allowfullscreen="true"
      class="catalogo-iframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Catalogo',
};
</script>

<style scoped>
/* Estilos específicos para el catálogo */
.catalogo-container {
  width: 80%;
  max-width: 3000px;
  text-align: center;
 
  margin-left: auto;
  margin-right: auto;
}

h1 {
  color: #d6d6d6;
  margin-bottom: 20px;
  font-size: 2rem;
}

.catalogo-iframe {
  width: 100%;
  height: 900px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 913px) {

/* Estilos específicos para el catálogo */
.catalogo-container {
  width: 100%;
  background-color: transparent;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  color: #d6d6d6;
  margin-bottom: 20px;
  font-size: 2rem;
}

.catalogo-iframe {
  width: 100%;
  height: 700px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

}
</style>
